<template>
    <div class="stage-inner-container-padded">
        <div v-if="appletLoadCompleted">
            <b-row >
                <b-col cols="10">
                    <h1>{{choice_list_name_pluaral}}</h1>
                </b-col>
                <b-col cols="2">
                        <i class="material-icons add-link float-right" 
                        v-if="$store.getters.checkUIPC({path: ['deviceModels', 'actions', 'create'] })"
                        @click="createNewRow()"
                        @mouseover="createNewHoverStart"
                        @mouseleave="createNewHoverEnd">
                            add_box
                        </i>
                </b-col>
            </b-row>
            <b-table 
                striped 
                hover 
                small 
                dark
                :items="values" 
                :fields="fields"
                :sort-compare-options="{ numeric: true, sensitivity: 'base' }"
            >
                <template v-slot:cell(alias)="data">
                    <span v-for="(aliasItm, idx) in data.value" :key="idx">
                        - {{aliasItm}} <br>
                    </span>
                </template>
                <template v-slot:cell(controls)="data">
                    <b-button-group size="sm">
                    <b-button class='material-icons' variant="info" v-if="$store.getters.checkUIPC({path: ['deviceModels', 'actions', 'update'] })" @click="editRow(data.item)">edit</b-button>
                    <!-- <b-button class='material-icons' variant="danger" @click="showConfirmationModal(confirmDeleteModal, data.item, data.item.value)">remove_circle_outline</b-button> -->
                    </b-button-group>
                </template>
            </b-table>
            <!-- TODO: Modal Buttons -->
            <b-modal id="choice-editor" size="lg">
                <template slot="modal-title">
                    {{modalValues.type}} {{choice_list_name_singular}}
                </template>
                 <!-- Manufacturer Dropdown -->
                <b-form-group 
                    id="fieldset-horizontal-dev-manuf"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    label="Manufacturer"
                    label-for="input-horizontal-dev-manuf"
                >
                   <b-form-select v-model="modalValues.manufacturer" :options="manufChoices" @input="modalValueChanged()"></b-form-select>
                </b-form-group >
                <!-- Device Type Dropdown -->
                <b-form-group 
                    id="fieldset-horizontal-dev-type"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    label="Type"
                    label-for="input-horizontal-dev-type"
                >
                   <b-form-select v-model="modalValues.device_type" :options="typeChoices" @input="modalValueChanged()"></b-form-select>
                </b-form-group >

                <b-form-group
                    id="fieldset-horizontal"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    label="Model"
                    label-class="font-weight-bold pt-0"
                    label-for="input-horizontal"
                    >
                    <b-form-input :state="modalValues.valueValid" id="input-horizontal" v-model="modalValues.value" @input="modalValueChanged()"></b-form-input>
                    <span class="input-text-alert" v-show="(modalValues.valueValid == false )" id="new-value-invalid-reason">
                    {{modalValues.valueInvalidReason}}
                </span>
                </b-form-group>
                
                
                <!-- Group And Label Aliases -->
                <b-form-group 
                    label-cols-lg="3" 
                    label-class="font-weight-bold pt-0" 
                    class="mb-0" 
                    label="Aliases">
                    <!-- For Each Alias Add A Row -->
                    <!-- This DIV creates iterable container and allows us to space the rows out without causing any css artifacts -->
                    <div class="array-spaced" v-for="(alias, index) in modalValues.aliases" :key="index">
                        <b-input-group 
                            id="fieldset-horizontal" 
                            label-cols-sm="4" 
                            label-cols-lg="3" 
                            description="" 
                            label-for="input-horizontal" 
                        >
                        <!-- Actual Alias Input -->
                        <b-form-input 
                            id="input-horizontal" 
                            v-model="modalValues.aliases[index]"
                            :state="modalValues.aliasValid[index]"
                            @change="checkAliasUniqunessOnEdit(index)"
                        ></b-form-input>
                        <!-- Append The Delete Button To The Alias Input -->
                        <b-input-group-append>
                            <b-button class='material-icons modal-btn' variant="danger" @click="removeSelectedAlias(index)">remove_circle_outline</b-button>
                        </b-input-group-append>
                        </b-input-group>
                        <!-- Text Alert Space For The Alias -->
                        <span class="input-text-alert" v-show="(modalValues.aliasValid[index] == false )" id="new-alias-invalid-reason">
                            {{ (modalValues.aliasInvalidMessages[index] || '') }}
                        </span>
                    </div>
                

                    <!-- Plus The One Default Empty One -->
                    <b-input-group 
                        key="newAlias"
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        description=""
                        label-for="input-horizontal"
                    >
                        <b-form-input 
                            id="input-horizontal" 
                            v-model="modalValues.newAlias" 
                            :state="modalValues.newAliasValid" 
                            @keyup.enter.native="addAlias()"
                            aria-describedby="new-alias-invalid-reason"
                        >
                        </b-form-input>
                        
                        <b-input-group-append>
                            <b-button 
                            class='material-icons modal-btn' 
                            variant="success" 
                            @click="addAlias()"
                            
                            >
                            add_circle_outline
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <span class="input-text-alert" v-show="(modalValues.newAliasValid == false )" id="new-alias-invalid-reason">
                        {{modalValues.newAliasInvalidReason}}
                    </span>
                </b-form-group>
                <b-row v-show="(modalValues.dbRejected == true )" id="db-rejected-invalid-reasons" v-for="(reason, index) in modalValues.dbRejectionCauses" :key="index">
                    <b-col class="input-text-alert">
                        {{reason}}
                    </b-col>
                </b-row>
                
                <!-- This Needs To Validate To Ensure That The Alias Isn't Shared Across Multiple Values -->
                <template slot="modal-footer">
                    <b-row v-if="!modalValues.checkedDuplicateWithDBCompleted" class="text-center" align-v="center">
                        <b-col cols="12">Record Must Be Validated before Saving</b-col>
                    </b-row>
                    <b-button-toolbar justify>
                        <b-button v-show="!modalValues.processing" class="mt-3" variant="danger" @click="$bvModal.hide('choice-editor')">Discard</b-button>
                        <b-button v-if="!modalValues.checkedDuplicateWithDBCompleted" class="mt-3" variant="info" @click="modalValidateInDB()">
                            <b-spinner v-show="modalValues.checking" label="Checking..."></b-spinner>
                            <span v-show="!modalValues.checking">Validate Model</span>
                        </b-button>
                        <b-button v-else class="mt-3" variant="success" @click="modalSave()">
                            <b-spinner v-show="modalValues.processing" label="Loading..."></b-spinner>
                            <span v-show="!modalValues.processing">Save</span>
                        </b-button> 
                    </b-button-toolbar>
                </template>
                
                
            </b-modal>
            <b-modal id="confirmation-window" size="lg">
            <template slot="modal-title">
                Are You Sure You Wish To Delete {{confirmationModal.title}}
            </template>
            <div v-show="confirmationModal.type == 'PERM'" >
                Deletion is Permanent And Cannot Be Undone, Are You Sure You Didn't Mean Disable?
            </div>
            <template slot="modal-footer">
                <b-button block class="mt-3" variant="success" @click="$bvModal.hide('confirmation-window')">Cancel</b-button>
                <!-- TODO: Setup Disable -->
                <b-button block v-show="confirmationModal.type == 'PERM'" class="mt-3" variant="warning" @click="$bvModal.hide('confirmation-window')">Disable Instead</b-button>
                <b-button block class="mt-3" variant="danger" @click="confirmationModal.callback(confirmationModal.callbackPass)">DELETE FOREVER</b-button>
                </template>
            </b-modal>
        </div>
        <div v-else>
            <h2>Select An Item To Get Started</h2>
        </div>
    </div>
</template>

<script>
/**
 * Known Issues:
 * Remove An alias, with box, attempt to recreate alias manually, this will be marked invalid until the save of this item, then reopening of the item.
 * 
 * TODO:
 *  drop down checklist for visible_fields
 */

const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');

const butils = require('../libs/basicUtils.js')

export default {
    name: 'vaapplet',
    components: {
    },
    data(){
        return{
            // Control Surfaces
            appletLoadCompleted: true,
            // Actual Choice List Stuff
            api_path: 'device_models/models',
            choice_list_name_singular: 'Device Model',
            choice_list_name_pluaral: 'Device Models',
            visible_fields: ['id', 'model', 'alias', 'manufacturer', 'type'],
            allPossibleFields: [],
            fields: [
                {key: "manufacturer_value", label: "Manufacturer", sortable: true},
                {key: "type_value", label: "Type", sortable: true},
                {key: "model", label: "Model", sortable: true},
                {key: "alias", label: "Alias / AKA", sortable: true},
                {key: "controls", label:"Controls", sortable: false}
            ],
            values: [],
            hasNoValues: false,
            modalValues: {},
            typeChoices: [],
            manufChoices: [],
            confirmationModal: {
              title: '',
              type: '',
              callbackPass: {},
              callback: ()=>{}
            }
        }
    },
    methods: {
        modalValueChanged(){
            this.modalValues.checkedDuplicateWithDBCompleted = false;
        },
        setupChoiceList(appletLoadObj){
            //console.log("You Called Sir");
            //console.log(appletLoadObj);
            this.api_path = appletLoadObj.api_path_prop;
            this.choice_list_name_singular = appletLoadObj.singular;
            this.choice_list_name_pluaral = appletLoadObj.plural;
            this.visible_fields = appletLoadObj.visiblefields;
            this.appletLoadCompleted = true;
            // Initial Setup
            this.fetchPageData();
            this.resetModalValue();
        },
        fetchPageData () {
            console.log(this.api_path)
            //Get User Token and Fetch The Values Required For This Page
            instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '.json?dref=true')
                .then(async (response) => {
                        // Set All Possible Field Values
                        //this.allPossibleFields = response.data.result.fields;
                        // Get Fields to display 
                        //var parsedDisplayFields = this.allPossibleFields.filter((f)=>{ return this.visible_fields.includes(f.key); });
                        ///this.fields = parsedDisplayFields;
                        // Push The Controls Field Into The Table
                        //this.fields.push({key: "controls", sortable: false, type: "NULL"});
                        // If There are any records to work with
                        if(response.data.result.records.length > 0){
                            // Set Them As The Values
                            this.values = response.data.result.records
                        }else{
                            // Set Values empty and flag no values
                            this.hasNoValues = true;
                            this.values =  [];
                        }
                })
                .catch((error) => {
                    if(butils.isError401(error)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                    }
                    console.log(error);
                });
        },
        fetchSecondaryChoices(){
            //Get Types
            instance.get(process.env.VUE_APP_API_BASE_URL + '/choices/device_types.json?sortasc=true')
                .then(async (response) => {
                    //console.log("Device Type Result");
                    //console.log(response.data.result.records);
                    // Add To Parent Selection Options
                    response.data.result.records.forEach((par)=>{
                        var tmp = {
                            value: par.id, 
                            text: par.value
                        };
                        this.typeChoices.push(tmp);
                    })
                })
                .catch((error) => {
                    console.log(error);
                });
            //Get Manufacturers
            instance.get(process.env.VUE_APP_API_BASE_URL + '/choices/device_manufacturers.json?sortasc=true')
                .then(async (response) => {
                    //console.log("Device Manufacturer Result");
                    //console.log(response.data.result.records);
                    // Add To Parent Selection Options
                    response.data.result.records.forEach((par)=>{
                        var tmp = {
                            value: par.id, 
                            text: par.value
                        };
                        this.manufChoices.push(tmp);
                    })
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateVisibleFieldSelection(){
            // Currently Unused, Will Be Used When User Can Change Visible Fields
            var parsedDisplayFields = this.allPossibleFields.filter((f)=>{ return this.visible_fields.includes(f.key); });
            this.fields = parsedDisplayFields;
        },
        clearConfirmationModal(){
            this.confirmationModal = {
            title: '',
            type: '',
            callbackPass: {},
            callback: ()=>{console.log('nope')}
            }
        },
        showConfirmationModal(confirmedCallback, callbackPass, title){
            console.log(callbackPass);
            this.clearConfirmationModal();
            this.confirmationModal.title = title;
            this.confirmationModal.type = 'PERM';
            this.confirmationModal.callbackPass = callbackPass;
            this.confirmationModal.callback = confirmedCallback;
            this.$bvModal.show('confirmation-window');
        },
        confirmDeleteModal(passed){
            //console.log('Modal Deletion CONFIRMED');
            //console.log(passed);
            // Create New Choice Item
            instance.delete(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '/' + passed.id + '.json')
            .then(async (response) => {
                this.fetchPageData();
                this.clearConfirmationModal();
                this.$bvModal.hide('confirmation-window');
            })
            .catch((error) => {
                console.log(error);
            });

        },
        openCreatorWindow(value) {
            this.modalValues.type = value;
            this.$bvModal.show('choice-editor')
        },
        createNewHoverStart(evt){
            evt.target.classList.add('add-link-hover')
        },
        createNewHoverEnd(evt){
            evt.target.classList.remove('add-link-hover')
        },
        resetModalValue(){
            this.modalValues = {
                id: '',
                type: '',
                value: '',
                device_type: '',
                manufacturer: '',
                aliases: [],
                aliasValid: [],
                aliasInvalidMessages: [],
                newAlias: '',
                newAliasValid: null,
                newAliasInvalidReason: 'blank',
                processing: false,
                checking: false,
                valueValid: null,
                valueInvalidReason: 'blank',
                checkedDuplicateWithDBCompleted: false,
                dbRejected: false,
                dbRejectionCauses: []
            }
            for (let i = 0; i < this.modalValues.aliases.length; i++) {
                this.modalValues.aliasValid.push(null);
                this.modalValues.aliasInvalidMessages.push('blank');
            
            }
        },
        pushAlias(alias){
            // Add To Array
            this.modalValues.aliasValid.push(null);
            this.modalValues.aliasInvalidMessages.push('blank');
            this.modalValues.aliases.push(alias);
            //Clear new Alias Value
            this.modalValues.newAlias = '';
        },
        createNewRow(){
            this.resetModalValue();
            this.openCreatorWindow("Create New");
        },
        checkAliasUniqunessOnEdit(aliasIndex){
            this.modalValueChanged();
            var edited = this.modalValues.aliases[aliasIndex];
            var id = this.modalValues.id;
            var valid = this.modalValues.aliasValid[aliasIndex];
            var invalidMessage = this.modalValues.aliasInvalidMessages[aliasIndex];
            // Check Against Values, Excluding Self
            var unique = this.checkUnique(edited, id, "ALIAS", aliasIndex);
            if(unique.res){
                this.modalValues.aliasValid[aliasIndex] = true;
                this.modalValues.aliasInvalidMessages[aliasIndex]  = 'blank';
                return true;
            }else if(unique.matchOn == 'VALUE'){
                this.modalValues.aliasValid[aliasIndex] = false;
                this.modalValues.aliasInvalidMessages[aliasIndex] = 'Overlaps Existing Value On: ' + unique.conflict[0].value;
            }else if(unique.matchOn == 'ALIAS'){
                this.modalValues.aliasValid[aliasIndex] = false;
                this.modalValues.aliasInvalidMessages[aliasIndex] = 'Overlaps Existing Alias On: ' + unique.conflict[0].value;
            }else if(unique.matchOn == 'SELF'){
                this.modalValues.aliasValid[aliasIndex] = false;
                this.modalValues.aliasInvalidMessages[aliasIndex] = 'Alias Not Unique';
            }else{
                this.modalValues.aliasValid[aliasIndex] = false;
                this.modalValues.aliasInvalidMessages[aliasIndex] = 'Invalid';
            }
            //return this.modalValues.aliasValid[aliasIndex];
            return false;
        },
        editRow(item){
            //console.log(val);
            this.resetModalValue();
            //This way because we need copies of the values
            this.modalValues.id = item.id.substring();
            this.modalValues.value = item.model.substring();
            this.modalValues.aliases = item.alias.slice();
            this.modalValues.device_type = item.type.substring();
            this.modalValues.manufacturer = item.manufacturer.substring();
            this.openCreatorWindow("Edit");
        },
        addAlias(event){
            this.modalValueChanged();
            // Ensure Not Blank
            // Should trim excess whitespace from arround the item in question.
            this.modalValues.newAlias = this.modalValues.newAlias.trim();
            var newAlias = this.modalValues.newAlias;
            // Should also check that the item isnt just blank space
            if(newAlias != null && newAlias.length >= 2){
                // Now we can check validation
                this.modalValues.newAliasValid = null;
                // Check Against The Existing Items
                // Check Validation - Ensure Not Duplicated Across - Duplicated Items Will Cause Lots of Problems Later
                var unique = this.checkUnique(newAlias,  this.modalValues.id, "ALIAS", -1);
                if(unique.res){
                    // Value is Unique
                    this.pushAlias(newAlias);
                }else if(unique.matchOn == 'VALUE'){
                    this.modalValues.newAliasValid = false;
                    this.modalValues.newAliasInvalidReason = 'Cannot Match An Existing Value - Matches Type: ' + unique.conflict[0].value;
                }else if(unique.matchOn == 'ALIAS'){
                    this.modalValues.newAliasValid = false;
                    this.modalValues.newAliasInvalidReason = 'Overlaps Existing Alias - Matches Alias On Type: ' + unique.conflict[0].value;
                }else if(unique.matchOn == 'SELF'){
                    this.modalValues.newAliasValid = false;
                    this.modalValues.newAliasInvalidReason = 'Alias Not Unique - Matches Already Existing Value or Alias';
                }else{
                    this.modalValues.newAliasValid = false;
                    this.modalValues.newAliasInvalidReason = 'Alias Not Unique';
                }
            }else{
                this.modalValues.newAliasValid = false;
                this.modalValues.newAliasInvalidReason = 'Value Must Be At Least Two Characters Long'
            }
        },
        removeSelectedAlias(index){
            // Remove Item From Modal Values Array
            this.modalValues.aliases.splice(index, 1);
            this.modalValues.aliasValid.splice(index, 1);
            this.modalValues.aliasInvalidMessages.splice(index, 1);
            // TODO Add Safety
        },
        modalSave(index){
            // Enforce A Wait
            this.modalValues.processing = true;
            // Value Validation
            // Trim Value To Remove Leading Or Trailing Spaces
            this.modalValues.value = this.modalValues.value.trim();
            // Validate That The Minimum Exists
            // Must Be 2 Characters Long Or More
            if(this.modalValues.value.length >= 2){
            var unique = this.checkUnique(this.modalValues.value, this.modalValues.id, "VALUE", -1);
            if(unique.res){
                //Not Stepping On Toes, Value is OKAY
                // Send Values To Backend
                this.modalValues.valueValid = true;
            }else if(unique.matchOn == 'VALUE'){
                this.modalValues.processing = false;
                this.modalValues.valueValid = false;
                this.modalValues.valueInvalidReason = 'Cannot Match An Existing Value - Matches: ' + unique.conflict[0].value;
            }else if(unique.matchOn == 'ALIAS'){
                this.modalValues.processing = false;
                this.modalValues.valueValid = false;
                this.modalValues.valueInvalidReason = 'Overlaps Existing Alias - Matches Alias On Type: ' + unique.conflict[0].value;
            }else if(unique.matchOn == 'SELF'){
                this.modalValues.processing = false;
                this.modalValues.valueValid = false;
                this.modalValues.valueInvalidReason = 'Value Not Unique - Matches Already Existing Value or Alias';
            }else{
                this.modalValues.processing = false;
                this.modalValues.valueValid = false;
                this.modalValues.valueInvalidReason = 'Value Not Unique ' + unique.matchOn;
            }
            }else{
            this.modalValues.valueValid = false;
            this.modalValues.valueInvalidReason = 'Value Must Be At Least Two Characters Long'
            }
            // Alias Validation
            var aliasesValid = true;
            for (let i = 0; i < this.modalValues.aliases.length; i++) {
                if(this.checkAliasUniqunessOnEdit(i)){
                    //Dont Change Anything This One Is Okay
                }else{
                    aliasesValid = false;
                }
            }
            // Read Result
            if(aliasesValid && this.modalValues.valueValid){
                //DO THE THING
                this.sendModalUpdate();
                // Break The Wait
                this.modalValues.processing = false;
                //Close The Window
                this.$bvModal.hide('choice-editor');
            }else{
                // Something Should Be Highlighted On Screen
                console.log('Save Failed')
            }
            
        },
        sendModalUpdate(){
            // TODO FROM HERE
            // if ID send request to update
            // if no ID send request to create
            if(this.modalValues.id == ''){
            // Create New Choice Item
            instance.post(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '.json', {
                model: this.modalValues.value,
                alias: this.modalValues.aliases,
                type: this.modalValues.device_type,
                manufacturer: this.modalValues.manufacturer
            })
                .then(async (response) => {
                    this.fetchPageData();
                })
                .catch((error) => {
                    console.log(error);
                });
            }else{
            instance.post(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '/' + this.modalValues.id + '.json', {
                id: this.modalValues.id,
                model: this.modalValues.value,
                alias: this.modalValues.aliases,
                type: this.modalValues.device_type,
                manufacturer: this.modalValues.manufacturer
            })
                .then(async (response) => {
                    this.fetchPageData();
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            
        },
        modalValidateInDB(){
            this.modalValues.checking = true;

            var model = {
                model: this.modalValues.value,
                type: this.modalValues.device_type,
                manufacturer: this.modalValues.manufacturer
            };

            if(this.modalValues.type != "Create New"){
                model.id = this.modalValues.id;
            }

            if(this.modalValues.aliases.length > 0){
                model.alias = this.modalValues.aliases;
            }

            instance.post(process.env.VUE_APP_API_BASE_URL + '/device_models/validate.json', model)
                .then(async (response) => {
                    this.modalValues.checking = false;
                    
                    // Todo: check if success, 
                        if(response.data.result.validates){
                            this.modalValues.checkedDuplicateWithDBCompleted = true;
                        }else{
                            this.modalValues.checkedDuplicateWithDBCompleted = false;
                            this.modalValues.dbRejected = true;
                            this.modalValues.dbRejectionCauses = [];
                            response.data.result.matches.forEach((match)=>{
                                var modelName = 'An Unknown Device';
                                var matchesOnModel = '';
                                if(match.matched == 'model'){
                                    if(match.input == 'model'){
                                        matchesOnModel = `matches the listed model, duplicates are forbidden`;
                                    }else{
                                        matchesOnModel = `matches one of the listed aliases, duplicates are forbidden`;
                                    }
                                    
                                }else{
                                    if(match.input == 'model'){
                                        matchesOnModel = `has an alias that matches the listed model, duplicates are forbidden`;
                                    }else{
                                        matchesOnModel = `has an alias that matches one of the listed aliases, duplicates are forbidden`;
                                    }
                                }

                                var filterRes = this.values.filter((val)=>{ return val.id == match.id });
                                if(filterRes.length > 0){
                                    modelName = `${filterRes[0].manufacturer_value} ${filterRes[0].type_value} ${filterRes[0].model}`
                                }
                                this.modalValues.dbRejectionCauses.push(`${modelName} ${matchesOnModel}`);
                            })
                        }
                })
                .catch((error) => {
                    this.modalValues.checking = false;
                    this.modalValues.checkedDuplicateWithDBCompleted = false;
                    console.log(error);
                    // TODO: dont leave the user hanging, write a message or something
                })
        },
        checkUnique(check, id, type, selfIndex){
            //Checks To Determine If A Given String is Unique Across All Values and Aliases
            /**
             * type: ["ALIAS", "VALUE"]
             * selfIndex: -1 for ignoreSelfIndex, int for index to exclude
             * returns: {
             *  res: true/false,
             *  matchOn: 'ALIAS'/'VALUE'
             *  conflict: {conflicting object}
             * }
             *  res is false on match found
             *  res is true on value unique
             */
            
            //Now Check Uniquness Against Our Own Values
            if(type === "ALIAS"){
            if(selfIndex != -1){
                if(this.modalValues.value == check){
                return {
                    res: false,
                    matchOn: 'SELF',
                    conflict: {}
                }
                }
                var matches = [];
                for (let i = 0; i < this.modalValues.aliases.length; i++) {
                if(this.modalValues.aliases[i] == check){matches.push(i)};
                }
                //Check IF matches exist
                if(matches.length > 0){
                // If Matches Were Found
                // Ignore Matching Self
                var invalid = false;
                for (let i = 0; i < matches.length; i++) {
                    if(matches[i] != selfIndex){ invalid = true; } 
                }
                // if we exclude ourself and something is still found, then its invalid
                if(invalid){
                    return {
                    res: false,
                    matchOn: 'SELF',
                    conflict: {}
                    }
                }
                }
                
            }else{
                if(this.modalValues.aliases.includes(check) || this.modalValues.value == check){
                return {
                    res: false,
                    matchOn: 'SELF',
                    conflict: {}
                }
                }
            }
            }else if(type === "VALUE"){
            if(this.modalValues.aliases.includes(check)){
                return {
                res: false,
                matchOn: 'SELF',
                conflict: {}
                }
            }
            }else{
            return {
                res: false,
                matchOn: 'ERROR',
                conflict: {}
                }
            }
            
            //If That Passes Then Validate Globally
            return this.globalUniquenessCheck(check, id);
        },
        globalUniquenessCheck(check, id){
            //Exclude Self in Global Values Checking
            var primaryValueMatches = this.values.filter((v) => { return (check == v.value && v.id != id) });
            var aliasValueMatches = [];
            //console.log(this.values)
            if(check != null){
                aliasValueMatches = this.values.filter((v) => { 
                    //console.log(v.alias);
                    if(v.alias != null){
                        return v.alias.includes(check) && v.id != id;
                    }else{
                        return false;
                    }
                });
            }
            if(primaryValueMatches.length == 0 && aliasValueMatches.length == 0){
            // No Matches Found
            return {
                res: true,
                matchOn: '',
                conflict: {}
                }
            }else{
            // Proposed Alias Already Exists
            if(primaryValueMatches.length > 0){
                // Proposed Alias Matches An Existing Value
                return {
                res: false,
                matchOn: 'VALUE',
                conflict: primaryValueMatches.concat(aliasValueMatches)
                }
            }else{
                // Proposed Alias Matches Alias Attached To An Existing Value
                return {
                res: false,
                matchOn: 'ALIAS',
                conflict: aliasValueMatches
                }
            }
            }
            //Something New
        }
    },
    beforeMount(){
        //console.log('Before Applet Mounted')
        // TODO: REPLACE THIS GARBAGE WITH A ROUTER
        this.fetchPageData();
        this.resetModalValue();
        this.fetchSecondaryChoices();
    }
}
</script>

<style>

</style>

<style scoped>
.modal-btn{
    font-size: 1.5rem;
    line-height: 1;
}
.array-spaced{
  margin-bottom: 0.5rem;
}
.input-text-alert{
  color: #dc3545;
}
</style>
